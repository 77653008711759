
import { defineComponent } from "vue";

export default defineComponent({
  name: "Learn",
  setup() {
    const sections = [
      {
        name: "Command Line (Beginners)",
        links: ["cBokz0LTizk"]
      },
      {
        name: "Install Kali on Windows (Beginners)",
        links: ["AfVH54edAHU"]
      },
      {
        name: "Install Kali Virtual Machine (Beginners)",
        links: ["V_Payl5FlgQ"]
      },
      {
        name: "Bash Scripting",
        links: ["v-F3YLd6oMw"]
      },
      {
        name: "What is a CTF ?",
        links: ["WAQGYz7AFIg", "E2OfooAKiHQ", "8ev9ZX9J45A"]
      },
      {
        name: "Wifi Hacking",
        links: ["WfYxrLaqlN8", "XaKJt6tSd6E"]
      },
      {
        name: "NMAP - Network Scanning",
        links: ["4t4kBkMsDbQ", "5MTZdN9TEO4"]
      },
      {
        name: "Metasploit",
        links: ["8lR27r8Y_ik", "TieUDcbk-bg"]
      },
      {
        name: "JohnTheRipper - Password Cracking",
        links: ["XjVYl1Ts6XI", "yhC5Kh5Z_4o"]
      },
      {
        name: "XSS",
        links: ["9kaihe5m3Lk", "oEFPFc36weY"]
      },
      {
        name: "SQL Injections",
        links: ["ciNHn38EyRc", "WFFQw01EYHM"]
      },
      {
        name: "Man in the middle Attack",
        links: ["J6Fm1Da5a4w", "C63PPEnFQnc"]
      },
      {
        name: "General",
        links: ["zyvwoWf6-ag"]
      }
    ];

    return {
      sections
    };
  }
});
